import { useEffect, useMemo, useState } from 'react';

export type UseInfiniteScrollProps = {
  fetchNextPage: () => void;
  options?: IntersectionObserverInit;
};

const useInfiniteScroll = <ThresholdElement extends Element = Element>({
  fetchNextPage,
  options,
}: UseInfiniteScrollProps) => {
  const [thresholdElement, thresholdElementRef] = useState<ThresholdElement | null>(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) return;
        fetchNextPage();
      }, options),
    [fetchNextPage, options]
  );

  useEffect(() => {
    if (!thresholdElement) return;

    observer.observe(thresholdElement);

    return () => {
      observer.unobserve(thresholdElement);
    };
  }, [observer, thresholdElement]);

  return { thresholdElementRef };
};

export default useInfiniteScroll;
