import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { WatchQueryFetchPolicy } from '@apollo/client';
import { Button } from 'components/Button';
import { PageControl } from 'components/PageControl';
import { PageSizeControl } from 'components/PageSizeControl';
import { Skeleton } from 'components/Skeleton';
import { NoDataDesign } from 'components/svg';
import {
  Maybe,
  OrderType,
  SearchLibraryCountQuery,
  SearchType,
  SortField,
  useSearchLibraryLazyQuery,
} from 'gql';
import { useTabs } from 'hooks';
import { useCookies } from 'react-cookie';
import { Link, useParams } from 'react-router-dom';
import { SearchResult } from 'types';
import { classes, pathTo } from 'utils';

import { ResourcesPanel } from '../ResourcesPanel';

interface useDataDesignProps {
  isPrivate: boolean;
  countData?: Maybe<SearchLibraryCountQuery>;
}

export const useDataDesign = <T extends SearchResult>({
  isPrivate,
  countData,
}: useDataDesignProps) => {
  const [sortBy, setSortBy] = useState<SortField>(SortField.CreatedAt);
  const [isList, setIsList] = useState(true);
  const startPageIndex = 1;
  const [before, setBefore] = useState<number | null | undefined>();
  const [after, setAfter] = useState<number | null | undefined>();
  const [pageIndex, setPageIndex] = useState<number>(startPageIndex);
  const [pageSize, setPageSize] = useState<number>(3);
  const [searchType, setSearchType] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { id } = useParams();
  const [{ account: accountCookie }] = useCookies(['account']);

  const [executeSearch, { data, loading: isLoading }] = useSearchLibraryLazyQuery();

  useEffect(() => {
    setPage(startPageIndex);
  }, [sortBy, searchType]);

  const getVariables = () => ({
    filters: {
      publisherAddress: isPrivate ? accountCookie?.address : id,
      searchType: searchType === 0 ? SearchType.SmartContracts : SearchType.Views,
    },
    limit: pageSize,
    after: Number(after),
    before: Number(before),
    sort: {
      field: sortBy,
      order: sortBy === SortField.Recommended ? OrderType.Asc : OrderType.Desc,
    },
  });

  const performSearch = (fetchPolicy: WatchQueryFetchPolicy = 'network-only') => {
    executeSearch({
      variables: getVariables(),
      fetchPolicy,
    });
  };

  useEffect(() => {
    performSearch();
  }, [id, pageSize, searchType, sortBy, isPrivate, accountCookie?.address, pageIndex]);

  const refreshSearchQuery = () => {
    performSearch('cache-and-network');
  };

  const maxPageIndex = useMemo(
    () => (count ? (count < pageSize ? 1 : Math.ceil(count / pageSize)) : 1),
    [count, pageSize]
  );

  const setPage = useCallback(
    (page: number | SetStateAction<number>, movement?: 'next' | 'previous') => {
      setPageIndex(page);
      if (page === 1) {
        setAfter(null);
        setBefore(null);
      } else if (movement === 'next') {
        setBefore(null);
        setAfter(data?.searchLibrary.data[data.searchLibrary.data.length - 1].id);
      } else if (movement === 'previous') {
        setAfter(null);
        setBefore(data?.searchLibrary.data[0].id);
      }
    },
    [data?.searchLibrary, setAfter, setBefore, setPageIndex]
  );

  const { tabPanels, tabSelector, selectedTab, setSelectedTab } = useTabs(
    [
      {
        child: (
          <ResourcesPanel<T>
            //@ts-ignore
            data={data?.searchLibrary.data}
            isList={isList}
            isLoading={isLoading}
            searchType={SearchType.SmartContracts}
            isEmptyView={
              <div className="w-full py-[81px] justify-center items-center flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
                <NoDataDesign className="mb-[18px]" />
                <div className="flex gap-2 flex-col mb-4">
                  <span className="">
                    {isPrivate ? "You don't" : "This publisher doesn't"} have any Smart Contract.
                  </span>
                  {isPrivate && <span>List a Smart Contract to get started</span>}
                </div>
                {isPrivate && (
                  <Link to={'?list-smart-contracts=true'}>
                    <Button variant="darkThemeFilled">List Smart Contract</Button>
                  </Link>
                )}
              </div>
            }
          />
        ),
        label: (
          <Skeleton.Loader isLoading={isLoading} className="w-20 h-6 pb-3 flex !rounded-[32px]">
            <div className="flex">
              Smart Contract
              {/* {
                <span className="ml-1 md:text-sm text-xs w-6 h-6 flex items-center justify-center bg-[#f7f8f814] rounded-full">
                  {countData?.searchLibraryCount?.smartContracts}
                </span>
              } */}
            </div>
          </Skeleton.Loader>
        ),
        value: 'smartContract',
      },
      {
        child: (
          <ResourcesPanel<T>
            //@ts-ignore
            data={data?.searchLibrary.data}
            isList={isList}
            isLoading={isLoading}
            searchType={SearchType.Views}
            isEmptyView={
              <div className="w-full py-[81px] justify-center items-center flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
                <NoDataDesign className="mb-[18px]" />
                <div className="flex gap-2 flex-col mb-4">
                  <span className="">
                    {isPrivate ? "You don't" : "This publisher doesn't"} have any data designed.
                  </span>
                  {isPrivate && <span>Build a view to get started</span>}
                </div>
                {isPrivate && (
                  <Link to={pathTo('ViewBuilder')}>
                    <Button variant="darkThemeFilled">View Builder</Button>
                  </Link>
                )}
              </div>
            }
          />
        ),
        label: (
          <Skeleton.Loader isLoading={isLoading} className="w-20 h-6 pb-3 flex !rounded-[32px]">
            <div className="flex">
              Views
              {/* {
                <span className="ml-1 md:text-sm text-xs w-6 h-6 flex items-center justify-center bg-[#f7f8f814] rounded-full">
                  {countData?.searchLibraryCount?.views}
                </span>
              } */}
            </div>
          </Skeleton.Loader>
        ),
        value: 'views',
      },
    ],
    0,
    {
      classNames: {
        base: 'justify-start text-start text-stone-50 text-base font-normal w-auto flex-wrap',
        tab: (selected) =>
          classes(
            'whitespace-pre px-3 py-2 h-10',
            selected &&
              'bg-white bg-opacity-10 rounded-3xl  backdrop-blur-xl justify-center items-start gap-1 inline-flex flex-[unset] w-fit'
          ),
      },
    }
  );

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setSearchType(0);
        setCount(countData?.searchLibraryCount?.smartContracts || 0);
        break;
      case 1:
        setSearchType(1);
        setCount(countData?.searchLibraryCount?.views || 0);
        break;
    }
  }, [countData, selectedTab]);

  const controls =
    count > 3 || isLoading ? (
      <div className="flex justify-between md:items-center items-start mt-4 mb-5 text-sm md:flex-row flex-col md:gap-0 gap-3">
        <PageControl
          onPrevious={() => setPage((prev) => Math.max(1, prev - 1), 'previous')}
          onNext={() => setPage((prev) => Math.min(maxPageIndex, prev + 1), 'next')}
          pageIndex={pageIndex - 1}
          setPageIndex={setPage}
          pageCount={maxPageIndex}
          totalItems={count || 0}
          pageSize={pageSize}
          hasNext={data?.searchLibrary.hasNext}
        />
        <PageSizeControl
          pageSize={pageSize}
          setPageIndex={setPage}
          setPageSize={setPageSize}
          startPageIndex={startPageIndex}
          className="flex items-center justify-center gap-[6px]"
        />
      </div>
    ) : null;

  return {
    refreshSearchQuery,
    sortBy,
    setSortBy,
    isList,
    setIsList,
    pageIndex,
    setPage,
    pageSize,
    setPageSize,
    searchType,
    setSearchType,
    count,
    setCount,
    isLoading,
    data,
    tabPanels,
    tabSelector,
    maxPageIndex,
    setSelectedTab,
    selectedTab,
    controls,
  };
};
