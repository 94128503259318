import { useEffect, useState } from 'react';

import { Tab } from '@headlessui/react';
import { Header, Modal, Skeleton } from 'components';
import { DeploySuccess } from 'components/DeploySuccess';
import SelectFunctions from 'components/SelectFunctions';
import { useApi, useSmartContract } from 'contexts';
import { useDataDesignQuery, useSmartContractFunctionsQuery } from 'gql';
import { useTabs } from 'hooks';
import { useListSmartContract } from 'hooks/useListSmartContracts';
import { useParams } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { classes, pathTo } from 'utils';

import { Contract } from './Components/Contract';

export function CollectionsContract() {
  const { resource: smartContract } = useSmartContract();
  const { id: address } = useParams();
  const { data: functionData } = useSmartContractFunctionsQuery({
    variables: { address: address as string },
    fetchPolicy: 'cache-first',
  });

  const [isAddFunctionOpen, setIsAddFunctionOpen] = useState(false);

  const { account } = useApi();

  const {
    mergeContractResult,
    isMergeContractError,
    smartContractAddress,
    setSmartContractDescription,
    setSmartContractIdentifier,
    setSmartContractAddress,
    setSelectedChain,
    setMergeContractResult,
    setIsMergeContractError,
    clearDryRunContractResult,
  } = useListSmartContract();

  useEffect(() => {
    if (isAddFunctionOpen) {
      setSmartContractDescription(smartContract?.description || '');
      setSmartContractIdentifier(smartContract?.name || '');
      setSmartContractAddress(smartContract?.address || '');
      setSelectedChain({ slug: smartContract.networks[0] as string, name: smartContract.networks[0] as string });
    } else {
      setSmartContractDescription('');
      setSmartContractIdentifier('');
      setSmartContractAddress('');
      setIsMergeContractError(false);
      setMergeContractResult(undefined);
    }
  }, [isAddFunctionOpen]);

  useEffect(() => {
    if (mergeContractResult && !isMergeContractError) {
      setSmartContractAddress('');
    }
  }, [mergeContractResult, isMergeContractError]);

  const { id } = useParams();
  const { data } = useDataDesignQuery({
    variables: {
      filters: {
        smartContractAddress: id,
      },
      limit: 3,
      page: 1,
      sort: null,
    },
  });
  const { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs } = useTabs(
    [
      {
        alternateLabel: data ? `Views (${data?.dataDesign?.count})` : 'Views',
        child: <Contract type="view" />,
        label: data ? `Views (${data?.dataDesign?.count})` : 'Views',
        value: 'views',
      },
      {
        alternateLabel: functionData
          ? `Function (${functionData?.smartContractFunctions?.length})`
          : 'Functions',
        child: <Contract type="function" />,
        label: functionData
          ? `Function (${functionData?.smartContractFunctions?.length})`
          : 'Functions',
        value: 'functions',
      },
      {
        alternateLabel: 'Contract Metadata',
        child: <Contract type="contract" />,
        label: 'Contract',
        value: 'contract',
      },
    ],
    0,
    {
      classNames: {
        base: 'card text-xs md:w-80 sm:w-[18rem] w-full !bg-transparent rounded-[32px] backdrop-blur-[12px] !border-[#ffffff33] p-1 sm:max-w-[284px] max-h-[40px]',
        tab: (_) => classes(' text-white text-sm', _ && 'bg-[#1B1B1B] rounded-[32px] '),
      },
    }
  );

  const CloseModal = () => setIsAddFunctionOpen(false);

  return (
    <div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Header
          accessory={
            <Skeleton.Loader isDarkTheme className="h-10 sm:w-[280px] w-full">
              {tabSelector}
            </Skeleton.Loader>
          }
          className="text-white sm:flex-row flex-col-reverse sm:items-center items-start gap-4 sm:mb-6 mb-3 "
          hClassName="sm:text-[32px] text-[20px]"
          additionalButton={
            <Skeleton.Loader className="h-5 w-20 !rounded-2xl">
              {account && (
                <button
                  data-cy="add-function-btn-sc-detail"
                  className="bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] text-transparent bg-clip-text text-sm"
                  onClick={() => setIsAddFunctionOpen(true)}
                >
                  Add Functions
                </button>
              )}
            </Skeleton.Loader>
          }
        >
          {tabs[selectedTab].alternateLabel ||
            reactStringReplace(tabs[selectedTab].label as string, /(\(\d+\))$/, (match) => (
              <Skeleton.Loader isDarkTheme className="h-5 w-[500px]">
                <span key="parens" className="font-normal text-neutral-500">
                  {match}
                </span>
              </Skeleton.Loader>
            ))}
        </Header>
        {tabPanels}
      </Tab.Group>
      <Modal
        isDarkTheme
        isOpen={isAddFunctionOpen}
        setIsOpen={setIsAddFunctionOpen}
        classNames={{ dialog: 'z-[100]' }}
        onClose={clearDryRunContractResult}
      >
        <div className="w-full">
          {mergeContractResult && !isMergeContractError && !smartContractAddress ? (
            <DeploySuccess
              submitTo={pathTo(
                'SmartContract',
                smartContract?.address as string,
                smartContract.networks[0] as string
              )}
              labels={{
                text: 'You have successfully added the new function(s) into the smart contract!',
                button: 'See Smart Contract',
              }}
              isOpen
              setIsOpen={setIsAddFunctionOpen}
              isModal={false}
              isDarkTheme
            />
          ) : (
            <SelectFunctions isAddFunctions tags={smartContract?.tags} CloseModal={CloseModal} />
          )}
        </div>
      </Modal>
    </div>
  );
}
