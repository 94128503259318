import { Layout } from 'components';
import {
  ApiKeyProvider,
  ApiProvider,
  MyProfileProvider,
  PublisherProvider,
  ResourceProvider,
} from 'contexts';
import { TabProvider } from 'contexts/Tab';
import { SearchType } from 'gql';
import { ViewBuilder } from 'pages/ViewBuilder';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { pathTo } from 'utils';

import * as pages from './pages';

export const router = createBrowserRouter([
  {
    element: (
      <ApiProvider>
        <Layout classNames={{ inner: 'md:pt-0 sm:px-0 px-3', base: 'bg-[#010101] ' }} isLanding />
      </ApiProvider>
    ),
    children: [
      {
        path: pathTo('Landing'),
        element: <pages.Landing />,
      },
    ],
  },
  {
    element: (
      <ApiProvider>
        <Layout
          isOnboarding
          classNames={{ inner: 'flex md:items-center justify-center md:pt-0' }}
        />
      </ApiProvider>
    ),
    children: [
      {
        path: pathTo('Onboarding'),
        element: <pages.Onboarding />,
      },
    ],
  },
  {
    element: (
      <ApiProvider>
        <TabProvider>
          <Layout />
        </TabProvider>
      </ApiProvider>
    ),
    children: [
      {
        path: pathTo('Library'),
        element: <pages.LibraryPage />,
      },
      {
        path: pathTo('MyProfile'),
        element: (
          <MyProfileProvider>
            <pages.MyProfile />
          </MyProfileProvider>
        ),
      },
      {
        path: pathTo('ViewBuilder'),
        element: <ViewBuilder />,
      },
      {
        path: pathTo('SmartContract', ':id', ':network'),
        element: (
          <ResourceProvider type={SearchType.SmartContracts}>
            <pages.SmartContractPage />
          </ResourceProvider>
        ),
      },
      {
        path: pathTo('View', ':id'),
        element: (
          <ResourceProvider type={SearchType.Views}>
            <pages.ViewPage />
          </ResourceProvider>
        ),
      },
      {
        path: pathTo('ApiKey', ':id'),
        element: (
          <ApiKeyProvider>
            <pages.ApiKey />
          </ApiKeyProvider>
        ),
      },
      {
        path: pathTo('Publisher', ':id'),
        element: (
          <PublisherProvider>
            <pages.PublisherPage />
          </PublisherProvider>
        ),
      },
      {
        path: pathTo('FundingActivity', ':id'),
        element: <pages.FundingActivity />,
      },
      {
        path: pathTo('BalanceHistory'),
        element: (
          <MyProfileProvider>
            <pages.BalanceHistory />
          </MyProfileProvider>
        ),
      },
    ],
  },
  {
    path: pathTo('NotFound'),
    element: <pages.NotFoundPage />,
  },
  {
    path: '/*',
    element: <Navigate to={pathTo('NotFound')} replace />,
  },
]);
