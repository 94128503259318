import { reservedKeywords } from 'consts';
import { ClassNames, HTMLAttributes, Maybe, Tag as TagBaseType, VoidFn } from 'types';
import { arrayOfSize, classes } from 'utils';
import { Skeleton, useSkeleton } from './Skeleton';
import { XMarkIcon } from './icons';

type TagType = TagBaseType & {
  name?: Maybe<string> | undefined;
};
interface Props$Tags extends HTMLAttributes<HTMLUListElement> {
  onRemove?: (_: string) => void;
  value?: TagType[];
  isDarkTheme?: boolean;
  classNames?: ClassNames<'tagBase' | 'tagName'>;
}

interface Props$Tag extends HTMLAttributes<HTMLLIElement> {
  classNames?: ClassNames<'tag' | 'name'>;
  as?: React.ElementType;
  onRemove?: VoidFn;
  value?: TagType;
  isDarkTheme?: boolean;
  isActive?: boolean;
  icon?: React.ReactElement;
}

interface Props$TagButton extends HTMLAttributes<HTMLLIElement> {
  value: TagType;
  icon?: React.ReactElement;
  classNames?: ClassNames<'button' | 'icon'>;
  toggleTag: (_: TagBaseType) => void;
  isActive: boolean;
}

const tagClassName = classes(
  'flex h-6 h-full items-center justify-center bg-neutral-100 px-2 font-medium uppercase text-neutral-500'
);
const reservedKeywordsStyle: any = {
  verified: 'bg-[_linear-gradient(117deg,_#BCF0B8_25.76%,_#8ABE81_74.26%)]',
  recommended: 'bg-[linear-gradient(117deg,_#70BDD6_25.76%,_#4E9BBC_74.26%)]', //'bg-gradient-to-r from-blue-400 via-blue-500 to-teal-400',
  trending: 'bg-[linear-gradient(275deg,_#8D74F7_5.07%,_#D285F7_46.17%,_#FFAD97_95.11%)]',
};
export function Tags({ className, onRemove, value, classNames, ...props }: Props$Tags) {
  const isLoading = useSkeleton();

  return (
    <ul
      {...props}
      className={classes(
        '-mb-2 flex flex-wrap items-center space-x-2 space-x-reverse text-[0.66rem] ',
        className
      )}
    >
      {(isLoading || !value ? arrayOfSize(3).map(() => undefined) : value).map((tag, i) => {
        return (
          <Tag
            classNames={{
              base: classes('first:mr-2', classNames?.tagBase),
              name: classNames?.tagName,
            }}
            key={tag?.slug || `placeholder-${i}`}
            onRemove={onRemove ? () => tag?.slug && onRemove && onRemove(tag.slug) : undefined}
            value={tag}
            isDarkTheme={props.isDarkTheme}
          />
        );
      })}
    </ul>
  );
}

export function Tag({
  as: Component = 'li',
  className,
  classNames,
  onRemove,
  value,
  isDarkTheme,
  isActive,
  icon,
  ...props
}: Props$Tag) {
  const tagValue = props?.children?.toString() || value?.name || '';
  const isReserved: boolean = reservedKeywords.some(
    (val) => val.toLowerCase() === tagValue.toLowerCase()
  );

  !icon &&
    isReserved &&
    (icon = (
      <img
        src={`/logos/reservedKeywordsIcon/${tagValue.toLowerCase()}.svg`}
        alt={tagValue}
        height={12}
        width={12}
      />
    ));

  return (
    <Component
      {...props}
      className={classes(
        'mb-2 h-6 max-w-[12rem]',
        isDarkTheme && 'rounded-2xl',
        classNames?.base,
        props.onClick && 'cursor-pointer'
      )}
    >
      <Skeleton.Loader
        isDarkTheme
        containerClassName="block h-full leading-none"
        heightFix
        isLoading={!value && !props.children}
        className="h-6 w-9"
      >
        <div
          data-cy={`${value?.slug}`}
          data-tag={value?.slug}
          className={classes(
            tagClassName,
            classNames?.tag,
            className,
            isDarkTheme && 'rounded-2xl px-[8px] py-[4px] bg-[#f7f8f81f] text-[#F7F8F8]',
            isActive && isDarkTheme && 'bg-white text-[#00010A]',
            icon && 'pl-1',
            isReserved && reservedKeywordsStyle[tagValue?.toLowerCase()]
          )}
        >
          {icon && (
            <div
              className={classes(
                'flex justify-center items-center h-5 w-5 rounded-full bg-[#343434] mr-[2px]',
                'bg-black'
              )}
            >
              {icon}
            </div>
          )}
          <div
            className={classes(
              'block pl-1 text-xs items-center overflow-hidden uppercase text-ellipsis whitespace-nowrap leading-none',
              classNames?.name,
              isReserved && 'text-black text-center font-normal  uppercase'
            )}
          >
            {props.children || value?.name}
          </div>
          {onRemove && (
            <button
              className="relative -top-[1px] cursor-pointer"
              data-tag={value?.slug}
              onClick={onRemove}
            >
              <XMarkIcon className="ml-1 h-4 w-4" />
            </button>
          )}
        </div>
      </Skeleton.Loader>
    </Component>
  );
}

export function TagButton({
  className,
  value,
  icon,
  classNames,
  toggleTag,
  isActive,
  ...props
}: Props$TagButton) {
  return (
    <li {...props} className={classes('text-xxs h-6', className, classNames?.base)}>
      <button
        data-tag={value}
        className={classes(
          tagClassName,
          'cursor-pointer rounded-2xl px-[8px] py-[4px] bg-[#f7f8f81f] text-[#F7F8F8]',
          icon && 'pl-1',
          classNames?.button,
          isActive && 'bg-white text-[#00010A]'
        )}
        onClick={() => toggleTag(value)}
      >
        {icon && (
          <div
            data-cy={`${value.name}`}
            className={classes(
              'flex mr-1 justify-center items-center h-5 w-5 rounded-full bg-[#343434]',
              classNames?.icon
            )}
          >
            {icon}
          </div>
        )}
        {value.name}
      </button>
    </li>
  );
}
