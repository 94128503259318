/* eslint-disable react/no-children-prop */
import { createRef, useEffect, useMemo, useState } from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { Page } from 'types';
import { classes } from 'utils';

import { Button } from './Button';
import { Details, Summary } from './Details';
import { DocumentIcon, ViewBuilderIcon, ViewIcon } from './svg';

type HelpPage = {
  title: string;
  md?: string;
  Icon: React.ReactNode;
  open?: string;
  button?: {
    label: string;
    link: string;
  };
  embeddedUrl?: string;
};

const HELP_PAGES: Partial<Record<Page | 'SmartContractList', HelpPage>> = {
  SmartContractList: {
    title: 'List Smart Contract',
    md: `- Click on the “Smart Contract” link at the top right of the screen. Ensure you’ve connected your wallet.
- Enter the smart contract details, including the contract address, identifier, and instance (e.g., testnet or mainnet).
- Select the chain where the smart contract is currently deployed and click “Add Functions”.
- Add the functions you want to use for your contract and click the “List” button.
`,
    Icon: <DocumentIcon className="h-[18px] w-[14px]  text-white" />,
    button: {
      label: 'Listing Smart Contracts',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/listing-smart-contracts',
    },
    embeddedUrl: 'https://jumpshare.com/embed/oImHtHI26qJA189c1QHn',
  },
  View: {
    title: 'Deploy a View',
    md: `- Click on the View Builder.
- Select the contract(s) and the relevant function(s) you want to index from the left panel.
- Verify that the autogenerated SQL statements in the Definition tab of the Console match the particular requirements for your use case and click on the “Test Query” button.
- If satisfied with the query results, click “Deploy View” to proceed. Otherwise, modify the query in the “Your Query” section.
- In the next screen, enter the View's name (you can use the identifier), type tags and a description, and click the “Next” button.
`,

    Icon: <ViewBuilderIcon className="h-[18px] w-[18px]" />,
    button: {
      label: 'Deploying Views',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/deploying-views',
    },
    embeddedUrl: 'https://jumpshare.com/embed/eXtTrHCf2TXtoJTOG6HO',
  },
  ViewBuilder: {
    title: 'Fund a View',
    md: `- Head over to the Library section by clicking on the “Library” link and select the View you want to fund. Ensure you’ve connected your wallet.
- Navigate to the “Add Funds” button and click on it.
- Enter the amount of tokens you would like to allocate to the View, then click the “Add Funds” button.
- Wait for the transaction to be finalized on the Timechain.
`,
    button: {
      label: 'Funding Views',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/funding-views',
    },
    Icon: <ViewIcon className="h-[18px] w-[18px]" />,
    embeddedUrl: 'https://jumpshare.com/embed/EXtwrl22GqoukNnJ6aBU',
  },
};

export function HelpMe() {
  const ref = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [openedSection, setOpenedSection] = useState<number | null>(null);
  const [userProgress, setUserProgress] = useState<number[]>([]);
  const progressStatus = useMemo(() => {
    const progress = (userProgress.length / Object.keys(HELP_PAGES).length) * 100;
    return progress.toFixed(0);
  }, [userProgress.length]);

  useEffect(() => {
    const progress = JSON.parse(localStorage.getItem('helpProgress') || '{}')?.progress;
    if (progress) {
      setUserProgress(progress);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('helpProgress', JSON.stringify({ progress: userProgress }));

    const iframeHandler = () => {
      setTimeout(() => {
        if (document.activeElement?.tagName === 'IFRAME') {
          //@ts-ignore
          if (!userProgress.includes(+document.activeElement.id)) {
            //@ts-ignore
            setUserProgress((arr) => [+document.activeElement.id, ...arr]);
          }
        }
      });
    };
    document.addEventListener('blur', iframeHandler, true);
    return () => document.removeEventListener('blur', iframeHandler, true);
  }, [userProgress]);

  useEffect(() => {
    const onClickOutside = ({ target }: MouseEvent) => {
      if (ref.current && !ref.current.contains(target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', onClickOutside, true);
    } else {
      document.removeEventListener('click', onClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, [isOpen, ref]);

  return (
    <div
      ref={ref}
      className={classes(
        'fixed flex flex-col max-w-fit items-end space-y-4 md:right-10 right-3 bottom-10 z-50 '
      )}
    >
      {isOpen && (
        <>
          <div
            className="fixed -z-10 sm:backdrop-blur-0 backdrop-blur-lg w-full h-full right-0 top-0"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              setIsOpen(!isOpen);
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsOpen(!isOpen);
              }
            }}
          />
          <div
            className={classes(
              '!bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] !border-[#1F1F1F] md:rounded-[24px] rounded-[14px] text-white right-0 ml-1.5 bottom-16 card overflow-hidden',
              openedSection !== null ? 'h-[550px]' : ''
            )}
          >
            <div
              className={classes(
                'md:w-[360px] sm:w-[295px] w-full py-6',
                openedSection !== null ? 'max-h-[500px]' : 'max-h-[344px]'
              )}
            >
              <div className="md:px-6 px-3">
                <h3 className="self-start uppercase text-lg leading-normal font-normal">
                  Quick Start
                </h3>
                <div className="text-[#B2B3B8] text-xs font-normal  leading-[18px] mt-1 mb-3">
                  Wondering where to start? Here’s everything you’ll want to learn to get started
                  with Analog Watch quickly.
                </div>
                <div className="h-[6px] w-full bg-[#ffffff1a] rounded-[17px] mb-[6px]">
                  <div
                    className="bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#7457B8_3%] via-[#B65ECA_45%] to-[#D97474_95%] h-full rounded-[17px] transition-[width] ease-linear delay-100"
                    style={{ width: `${progressStatus}%` }}
                  ></div>
                </div>
                <div className="text-xs font-normal text-[#B2B3B8] mb-4">
                  {progressStatus}% Completed
                </div>
              </div>
              <div
                className={classes(
                  'flex flex-col space-y-2 md:px-6 px-3 overflow-x-hidden overflow-y-scroll scrollbar-dark',
                  openedSection !== null ? 'h-[500px] pb-28' : 'h-[182px]'
                )}
              >
                {Object.entries(HELP_PAGES).map(
                  ([page, { title, Icon, md, open, button, embeddedUrl }], index) => {
                    if (!md && !embeddedUrl) {
                      return (
                        <div
                          key={index}
                          className="group border border-[#ffffff1f] text-white bg-gradient-to-t from-[#0E0E0E_0%] to-[#0E0E0E_100%] rounded-2xl w-full h-[55px] border-none p-3 cursor-pointer"
                          onClick={() => {
                            window.open(open, '_self');
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              window.open(open, '_self');
                            }
                          }}
                        >
                          <div className="relative flex mt-[-1px] items-center border-0 border-b border-[#ffffff1f] text-base border-none p-0">
                            <div className="bg-[#ffffff14] h-8 w-8 flex items-center justify-center rounded-lg mr-2">
                              {Icon}
                            </div>
                            <div className="text-sm">{title}</div>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <Details
                        key={index}
                        open={openedSection === index}
                        className={classes(
                          'text-white bg-gradient-to-t from-[#0E0E0E_0%] to-[#0E0E0E_100%] rounded-2xl w-full border-none p-3 cursor-pointer',
                          (openedSection === index || userProgress.includes(index)) &&
                            'bg-none bg-transparent rounded-2xl border border-solid border-[#1F1F1F] p-[11px]'
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          if (openedSection !== index) {
                            setOpenedSection(index);
                          }
                          if (!embeddedUrl && !userProgress.includes(index)) {
                            setUserProgress((arr) => [index, ...arr]);
                          }
                        }}
                      >
                        <Summary
                          classNames={{
                            base: classes('border-none p-0 select-none'),
                          }}
                          onClick={() => {
                            if (openedSection === index) {
                              setOpenedSection(null);
                            }
                          }}
                        >
                          <div className="bg-[#ffffff14] h-8 w-8 flex items-center justify-center rounded-lg mr-2">
                            {Icon}
                          </div>
                          <div className="text-sm">{title}</div>
                        </Summary>
                        <div className="md:mt-2 mt-0 text-[#B2B3B8]">
                          {embeddedUrl && openedSection == index && (
                            <div className="md:w-[284px] w-[244px] h-[194px] bg-[#1c1c1c] relative">
                              <iframe
                                title="Embeded Help Video"
                                id={index.toString()}
                                src={embeddedUrl}
                                width="100%"
                                height="194"
                                allow="autoplay"
                                allowFullScreen
                              />
                            </div>
                          )}
                          <h4 className="md:mt-6 mt-3 mb-2 text-sm">{title}</h4>
                          {md && (
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw as never]}
                              className="markdown text-xs leading-[18px] !mb-0"
                            >
                              {md}
                            </ReactMarkdown>
                          )}
                          {button && (
                            <div className="flex space-x-2 text-xs">
                              <span className="!ml-1">Refer to</span>
                              <Link
                                className="hyperlink !ml-1 flex items-center !bg-[linear-gradient(283deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text !text-transparent"
                                to={button.link}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {button.label}
                              </Link>
                              <span className="!ml-1">to learn more</span>
                            </div>
                          )}
                        </div>
                      </Details>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Button
        variant="primary"
        className={classes(
          '!bg-white rounded-[9999px] to-red-400 h-12 justify-center items-center !border-none cursor-pointer select-none sm:!py-3 !py-[11px] px-6',
          !isOpen && 'bg-gradient-to-l  from-purple-500 !bg-transparent !pl-[22px] !pr-6'
        )}
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {!isOpen ? (
          <div className="flex gap-1 items-center">
            <QuestionMarkCircleIcon className="w-7 h-7 stroke-1" />
            <p className="font-normal text-base normal-case">Help</p>
          </div>
        ) : (
          <div className="text-black font-normal text-base normal-case">Close</div>
        )}
      </Button>
    </div>
  );
}
