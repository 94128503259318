import { Fragment, useRef } from 'react';

import { Combobox } from '@headlessui/react';
import { Button, MagnifyingGlassIcon, Skeleton, TagIcon } from 'components';
import { useLibrary } from 'contexts';
import reactStringReplace from 'react-string-replace';
import { Suggestion } from 'types';
import { classes } from 'utils';

import useSearchLibrary from './useSearchLibrary';

export const menuGroupClassName = 'text-xs py-2 px-3 uppercase';
export const menuOptionClassName =
  'flex items-start whitespace-pre-line break-all cursor-pointer select-none relative text-sm p-3 hover:bg-gray-100';

const DEFAULT_SUGGESTIONS = [
  {
    value: 'ETHEREUM',
    isTag: true,
  },
  {
    value: 'ASTAR',
    isTag: true,
  },
  {
    value: 'Verified',
    isTag: true,
  },
];

export function Search() {
  const input = useRef<HTMLInputElement | null>(null);
  const { setSearchTerm, toggleTag } = useLibrary();
  const { suggestions, searchString, setSearchString } = useSearchLibrary();
  return (
    <Combobox<Suggestion>
      onChange={(s) => {
        if (s.isTag) {
          toggleTag({ name: s.value as string, slug: s.value as string });
        } else if (s.value) {
          setSearchTerm(s.value);
        }
        setSearchString('');
      }}
    >
      <div className={classes('relative h-10 flex-1 md:max-w-full md:min-w-[288px] lg:max-w-full')}>
        <Skeleton.Loader
          heightFix
          isDarkTheme
          containerClassName="w-full flex justify-end items-center"
          className="h-10 w-10 !rounded-[32px]"
        >
          <Combobox.Input
            data-cy="input-search-box-library"
            className={classes(
              'w-full flex-1 border-gray-200 pb-2 pl-3 pr-4 pt-2 placeholder:text-neutral-400 text-sm'
            )}
            displayValue={() => ''}
            onChange={(e) => {
              const value = e.target.value;
              const filteredValue = value.replace(/[^a-zA-Z0-9_]/g, ''); // Allow only letters, numbers and underscores
              setSearchString(filteredValue);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchTerm(searchString || '');
                setSearchString('');
              }
            }}
            placeholder="Search..."
            ref={input}
            autoComplete="off"
            value={searchString || ''}
          ></Combobox.Input>
          <div className="absolute right-0 top-0 h-full shrink-0 p-0.5">
            <Button
              data-cy="Search-btn-library"
              isIcon
              variant="darkThemeFilled"
              className="aspect-square shrink-0 bg-red-500"
              onClick={() => {
                if (searchString) {
                  setSearchTerm(searchString || '');
                  setSearchString('');
                }
              }}
            >
              <MagnifyingGlassIcon className="h-5 w-5" color="black" />
            </Button>
          </div>
        </Skeleton.Loader>
        <Combobox.Options className="bg-black rounded-lg border border-[#ffffff14] absolute left-0 top-full z-10 w-full mt-[6px]">
          <div className={menuGroupClassName}>
            {searchString && searchString?.length <= 2 ? 'Popular Tags' : 'Results'}
          </div>
          {searchString && searchString.length > 0 && (
            <Combobox.Option
              key={searchString}
              value={{ label: searchString, value: searchString, isTag: false }}
              as={Fragment}
            >
              {() => (
                <li className={classes(menuOptionClassName, 'items-center')}>
                  <MagnifyingGlassIcon className="mr-1 h-4 w-4" />
                  <div>Search for &apos;{searchString}&apos;</div>
                </li>
              )}
            </Combobox.Option>
          )}
          {(searchString && searchString?.length >= 3 ? suggestions : DEFAULT_SUGGESTIONS)?.map(
            (s) => {
              return (
                <Combobox.Option key={(s.value as string) + Math.random()} value={s} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={classes(
                        menuOptionClassName,
                        'items-center',
                        active && 'bg-[#101010]'
                      )}
                    >
                      {s.isTag ? (
                        <TagIcon className="mr-1 h-4 w-4" />
                      ) : (
                        <MagnifyingGlassIcon className="mr-1 h-4 w-4" />
                      )}
                      <div>
                        {reactStringReplace(
                          s.value as string,
                          new RegExp(`\\b(${searchString?.toLowerCase()})`, 'i'),
                          (match) => (
                            <span className="font-bold">{match}</span>
                          )
                        )}
                      </div>
                    </li>
                  )}
                </Combobox.Option>
              );
            }
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
