import { useState } from 'react';

export const Header = () => {
  const [isReadMoreExpanded, setIsReadMoreExpanded] = useState(false);
  return (
    <div className="md:mb-6 mb-3">
      <h1 className="mb-2 sm:text-[56px] text-[32px]">Library</h1>
      <div className="sm:text-lg text-sm max-w-[800px] text-[#F3EAEC]">
        The Library is a marketplace of listed smart contracts and published Views.
        <button
          className={`text-white ${isReadMoreExpanded ? 'hidden' : 'inline'} sm:hidden`}
          onClick={() => setIsReadMoreExpanded(true)}
        >
          .. Read More
        </button>
        <span className={`${isReadMoreExpanded ? 'inline' : 'hidden'} sm:inline`}>
          {' '}
          Use the listed smart contracts to build new Views or explore published Views directly in
          your browser.
        </span>
      </div>
    </div>
  );
};
