import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { Button, CustomExcalmationMark, Modal } from 'components';
import { FundsModal } from 'components/FundsModal';
import useDepositFund from 'hooks/tokenomics/useDepositFund';

interface DepositFundProps {
  accountBalance?: BigNumber;
  setIsOpen: ((_: boolean) => void) | undefined;
  successModalSubmitTo?: string;
  renderContent?: React.ReactElement;
}

const DepositFund = ({ renderContent, ...props }: DepositFundProps) => {
  const {
    Summary,
    amount,
    amountError,
    depositStatus,
    depositHash,
    setDepositHash,
    error,
    fundRefAddress,
    handleSubmit,
    isDepositLoading,
    setAmount,
    isGasFeeLoading,
  } = useDepositFund();
  return depositStatus === 'Error' ? (
    <Modal
      isOpen={true}
      setIsOpen={props.setIsOpen}
      isDarkTheme={true}
      classNames={{ base: '!max-w-[27rem]' }}
    >
      <div className="flex flex-col items-center text-center text-white gap-2">
        <CustomExcalmationMark />
        <div className="mx-4 text-2xl mt-4">Your Deposit Failed!</div>
        <div className="mx-4 text-sm text-[#b2b3b8]">
          Click
          <Link
            target="_blank"
            to={`https://explorer.${import.meta.env.MODE}.analog.one/#/block/${depositHash}`}
            className="pl-1 bg-[linear-gradient(283deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text !text-transparent"
          >
            here
          </Link>
          <span className="ml-1 text-base text-[#b2b3b8]">for more details</span>
        </div>
        <div className="flex my-2 gap-2">
          <Button
            variant="plain"
            className="border border-solid bg-white text-black border-white px-5 rounded-full"
            onClick={() => {
              props.setIsOpen && props.setIsOpen(false);
              setDepositHash('');
            }}
          >
            See my profile
          </Button>
        </div>
      </div>
    </Modal>
  ) : (
    <FundsModal
      {...props}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      depositStatus={depositStatus}
      error={error}
      fundRefAddress={fundRefAddress}
      onSubmit={handleSubmit}
      isLoading={isDepositLoading}
      Summary={Summary}
      showBalance
      isOpen
      texts={{
        header: 'Fund your Account',
        headerSub:
          'Start deploying and querying views right away by depositing $TANLOG into your Watch account!',
        submit: 'Deposit',
        inputLabel: 'Deposit amount',
        inputPlaceHolder: 'Amount',
      }}
      successTexts={{
        header: `Great news! Your funds have been successfully deposited. 🎉`,
        submitToButton: 'See My Profile',
      }}
      renderContent={renderContent}
      disabledSubmitButton={isGasFeeLoading}
    />
  );
};

export default DepositFund;
