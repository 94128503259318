import { useEffect, useState } from 'react';

import { PageControl } from 'components';
import { PageSizeControl } from 'components/PageSizeControl';
import { useLibrary } from 'contexts';

const useLibraryList = () => {
  const {
    isLoading,
    isTagsLoading,
    reset,
    currentPage,
    pageIndex,
    setPage,
    pageSize,
    hasNext,
    setPageSize,
    searchTerm,
    searchType,
    setSearchTerm,
    refreshSearchQuery,
    toggleTag,
  } = useLibrary();

  const [throttleLoading, setThrottleLoading] = useState<boolean>(false);
  const [queryCooldown, setQueryCooldown] = useState<boolean>(false);

  useEffect(() => {
    if (queryCooldown) {
      const cooldownTimeout = setTimeout(() => {
        setQueryCooldown(false);
      }, 15000);
      return () => clearTimeout(cooldownTimeout);
    }
  }, [queryCooldown]);

  const handleRefresh = () => {
    if (queryCooldown) {
      setThrottleLoading(true);
      setTimeout(() => {
        setThrottleLoading(false);
      }, 1000);
    } else {
      setQueryCooldown(true);
      refreshSearchQuery();
    }
  };

  const controls =
    currentPage?.length || isLoading || throttleLoading ? (
      <div className="flex justify-between md:items-center items-start mt-4 mb-5 h-10 text-sm md:flex-row flex-col md:gap-0 gap-3">
        <PageControl
          onPrevious={() => setPage((prev) => Math.max(1, prev - 1), 'previous')}
          onNext={() => setPage((prev) => Math.max(prev + 1, 2), 'next')}
          pageIndex={pageIndex - 1}
          setPageIndex={setPage}
          pageSize={pageSize}
          hasNext={hasNext}
        />
        <PageSizeControl
          pageSize={pageSize}
          setPageSize={setPageSize}
          className="flex items-center justify-center gap-[6px]"
          startPageIndex={1}
        />
      </div>
    ) : null;
  return {
    isLoading,
    isTagsLoading,
    reset,
    searchTerm,
    searchType,
    throttleLoading,
    handleRefresh,
    controls,
    setSearchTerm,
    currentPage,
    toggleTag,
  };
};
export default useLibraryList;
