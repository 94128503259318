import { LibraryProvider } from 'contexts';
import { useBreadCrumbsRoot } from 'hooks/useBreadCrumbs';

import { Header as LibraryHeader } from './Components/Header/Header';
import LibraryList from './Components/LibraryList/LibraryList';

const Library = () => {
  useBreadCrumbsRoot([{ page: 'Library' }]);
  return (
    <div className="mb-4 lg:p-16 pt-2 px-0 text-white">
      <LibraryHeader />
      <LibraryProvider>
        <LibraryList />
      </LibraryProvider>
    </div>
  );
};

export default Library;
