import { ArrowRightIcon, Button } from 'components';
import { Link } from 'react-router-dom';
import { SearchType, VoidFn } from 'types';
import { pathTo } from 'utils';

interface NoDataViewProps {
  searchTerm: string | undefined;
  searchType: SearchType;
  reset: VoidFn;
}

const NoDataFound = ({ searchTerm, searchType, reset }: NoDataViewProps) => {
  return searchTerm ? (
    <>
      <div
        data-cy="invalid-search-error"
        className="text-center text-[#ffffff99] max-w-[600px] text-opacity-60 text-base font-normal leading-normal my-3"
      >
        The Artifact you&apos;re looking for doesn&apos;t exist. Check whether you&apos;ve typed the
        correct address or revisit the Library by clicking on the link below.
      </div>
      <Button className="flex item-center" onClick={reset} variant="plain">
        <span>Back to the Library</span>{' '}
        <ArrowRightIcon className="h-[14px] w-[14px] ml-2 mt-0.5" />
      </Button>
    </>
  ) : searchType === 'smartContracts' ? (
    <>
      <div className="text-center text-[#ffffff99] max-w-[600px] text-opacity-60 text-base font-normal leading-normal my-3">
        It seems there&apos;s currently no Smart Contract to display. You can create a new Smart
        Contract to get started.
      </div>
      <Link to={'?list-smart-contracts=true'}>
        <Button variant="darkThemeFilled">List a new Smart Contract</Button>
      </Link>
    </>
  ) : searchType === 'views' ? (
    <>
      <div className="text-center text-[#ffffff99] max-w-[600px] text-opacity-60 text-base font-normal leading-normal my-3">
        It seems there&apos;s currently no View to display. You can build a new View to get started.
      </div>
      <Link to={pathTo('ViewBuilder')}>
        <Button variant="darkThemeFilled">View Builder</Button>
      </Link>
    </>
  ) : null;
};
export default NoDataFound;
