import { useEffect, useMemo, useState } from 'react';

import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { Button, CheckBox, Input, Loader, MagnifyingGlassIcon } from 'components';
import { useApi } from 'contexts';
import { Maybe, TimegraphTag } from 'gql';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { classes, removeIdentifier } from 'utils';

import { useListSmartContract } from '../hooks/useListSmartContracts';
import { CreateSmartContractByAbi } from '../pages/ListSmartContracts/CreateSmartContractByAbi';

type SelectFunctionsProps = {
  handleSelectedTab?: (tabIndex: number) => void;
  tags?: Maybe<Maybe<TimegraphTag>[]>;
  isAddFunctions?: boolean;
  CloseModal?: () => void;
};

function SelectFunctions({
  handleSelectedTab,
  tags,
  isAddFunctions,
  CloseModal,
}: SelectFunctionsProps) {
  const [searchString, setSearchString] = useState('');
  const [selectedFunctionsSpecs, setSelectedFunctionsSpecs] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useApi();
  const {
    isDryRunContractLoading,
    isMergeContractLoading,
    functionOptions,
    isDryRunContractError,
    listSmartContract,
    mergeContractResult,
    isMergeContractError,
    smartContractIdentifier,
    editTags,
    dryRunContract,
    smartContractAddress,
    selectedChain,
  } = useListSmartContract();
  const { sessionKey } = useApi();

  useEffect(() => {
    if (isAddFunctions && smartContractIdentifier && smartContractAddress) {
      dryRunContract({
        variables: {
          data: {
            address: smartContractAddress,
            chain: 'mainnet',
            identifier: smartContractIdentifier,
            network: selectedChain?.slug as string,
          },
          sessionKey: sessionKey as string,
        },
      });
    }
  }, [
    smartContractIdentifier,
    isAddFunctions,
    smartContractAddress,
    dryRunContract,
    selectedChain?.slug,
    sessionKey,
  ]);

  const isLoading = useMemo(
    () => isDryRunContractLoading || isMergeContractLoading,
    [isDryRunContractLoading, isMergeContractLoading]
  );

  useEffect(() => {
    if (
      mergeContractResult &&
      !isMergeContractError &&
      mergeContractResult?.MergeContract?.status === 'Completed'
    ) {
      handleSelectedTab && handleSelectedTab(2);
    } else if (isMergeContractError) {
      mergeContractResult?.MergeContract?.error &&
        toast.error(mergeContractResult?.MergeContract.error, {
          position: 'top-right',
          duration: 5000,
        });
      handleSelectedTab && handleSelectedTab(0);
    }
  }, [isMergeContractError, mergeContractResult]);

  const handleClick = (functionHashId: string) => {
    if (selectedFunctionsSpecs.includes(functionHashId)) {
      setSelectedFunctionsSpecs((prev) => prev.filter((item) => item !== functionHashId));
    } else {
      setSelectedFunctionsSpecs((prev) => [...prev, functionHashId]);
    }
  };

  const handleListSmartContract = () => {
    if (account) {
      listSmartContract(selectedFunctionsSpecs);
    } else {
      searchParams.set('connect', 'true');
      setSearchParams(searchParams);
    }
  };

  const isAllFunctionListed = useMemo(
    () =>
      functionOptions?.filter((el) => el?.status === 'Untouched').length ===
      functionOptions?.length,
    [functionOptions]
  );

  const button = useMemo(() => {
    const Listbutton = (
      <Button
        className={classes(
          'px-5 rounded-full h-12 mx-auto block mt-6 w-32',
          selectedFunctionsSpecs.length > 0 ? 'bg-white' : 'bg-[#7a7a7a]'
        )}
        variant="plain"
        onClick={handleListSmartContract}
        isDisabled={selectedFunctionsSpecs.length === 0}
      >
        <span data-cy="list-btn-sc" className="text-base text-black">
          List
        </span>
      </Button>
    );

    return isAllFunctionListed ? (
      <Tooltip
        arrow
        placement="top"
        title={
          functionOptions?.length
            ? 'All the function for this Smart contract are already listed.'
            : 'There is no available functions for this smart contract.'
        }
        classes={{ tooltip: '!text-sm text-center' }}
      >
        {Listbutton}
      </Tooltip>
    ) : (
      Listbutton
    );
  }, [handleListSmartContract, selectedFunctionsSpecs, isAllFunctionListed]);
  if (isLoading || (!isDryRunContractError && !functionOptions)) {
    return <Loader className="h-[320px] flex text-white" />;
  }

  if (!isLoading && isDryRunContractError) {
    return (
      <CreateSmartContractByAbi handleSelectedTab={handleSelectedTab} CloseModal={CloseModal} />
    );
  }

  return (
    <>
      <div className="flex justify-between ">
        <p
          data-cy={`Smart Contract:${smartContractIdentifier}`}
          className="text-2xl w-full whitespace-pre-line text-ellipsis break-all"
        >
          Smart Contract: {smartContractIdentifier}
        </p>
      </div>
      <p data-cy="select-function-popup-sc" className="text-sm mt-2 text-[#B2B3B8]">
        Please select the functions
      </p>
      <div className="mt-6 flex flex-col relative">
        <Input
          dataCy="input-search-function-sc"
          className="relative px-5"
          classNames={{ input: 'dark' }}
          isDarkTheme
          isClearable
          placeholder="Search"
          value={searchString}
          onChange={(e) => {
            const filteredValue = e.replace(/[^a-zA-Z_]/g, ''); // Allow only letters and underscores
            setSearchString(filteredValue);
          }}
        >
          <MagnifyingGlassIcon className="absolute left-2 top-2 h-5 w-5 text-neutral-400" />
        </Input>
      </div>
      <div className="mt-4 text-[#808080] text-xs flex flex-row justify-between">
        <div>
          {selectedFunctionsSpecs.length === 0
            ? 'No selected function'
            : `${selectedFunctionsSpecs.length} selected functions`}
        </div>
        {!isAllFunctionListed && (
          <button
            className="text-white cursor-pointer"
            onClick={() => setSelectedFunctionsSpecs([])}
          >
            Clear
          </button>
        )}
      </div>
      <ul className="flex gap-4 flex-col w-[100%] max-h-[266px] !mt-2 overflow-auto scrollbar-white">
        {(searchString
          ? functionOptions?.filter(
              (fnOption) =>
                fnOption?.name?.toLowerCase().match(searchString?.toLowerCase()) ||
                fnOption?.identifier?.toLowerCase().match(searchString?.toLowerCase())
            )
          : functionOptions
        )?.map((functionOption) => {
          const isActive = selectedFunctionsSpecs.includes(
            removeIdentifier(functionOption?.identifier, smartContractIdentifier) as string
          );
          const name = removeIdentifier(functionOption?.identifier, smartContractIdentifier);
          const isListed = functionOption?.status === 'Untouched';
          return (
            <li className="w-full" key={`collection-${functionOption?.identifier}`}>
              <button
                className={classes(
                  'flex items-center py-1 px-4 text-sm relative w-full rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] flex-row-reverse ',
                  isActive && 'bg-[#ffffff0d] border-[#ffffff0d]',
                  isListed && 'bg-[#ffffff0d] cursor-not-allowed opacity-60'
                )}
                disabled={isListed}
                onContextMenu={() => handleClick(name)}
                onClick={() => handleClick(name)}
              >
                <div className="w-full flex gap-2 items-center">
                  <Cog6ToothIcon className="h-6 w-6 shrink-0" color="grey" />
                  <div
                    data-cy={`${name}`}
                    className="flex-1 my-1 break-words"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {name}(
                    {(functionOption?.inputs as { value: string }[])
                      .map(({ value }) => value)
                      .join(', ')}
                    )
                    <p className="text-[#ffffff80] text-xs">
                      (
                      {(functionOption?.outputs as { name: string; value: string }[])
                        .map(({ name, value }) => `${name}:${value}`)
                        .join(', ')}
                      )
                    </p>
                  </div>
                </div>
                <div className="w-8 ml-2">
                  <CheckBox isChecked={isActive || isListed} isDarkTheme />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
      {button}
    </>
  );
}

export default SelectFunctions;
