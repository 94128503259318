import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tab } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Card, CustomInfoIcon, Header, Skeleton } from 'components';
import { useViewBuilder } from 'contexts';
import { useTabs, useWindowSize } from 'hooks';
import { useBreadCrumbsRoot } from 'hooks/useBreadCrumbs';
import { classes } from 'utils';

import { QueryDisplay } from './QueryDisplay';
import { SmartContractList } from './SmartContractList';
import { SpecControl } from './SpecControl';
const HEIGHT = 'calc(100vh - 236px)';

export const ViewBuilder = () => {
  const {
    selectedTab,
    setSelectedTab,
    functionOptions,
    errorFunctionSpecs,
    scroll,
    setScroll,
    errorSpec,
    setErrorSpec,
    functionSpecs,
  } = useViewBuilder();
  useBreadCrumbsRoot([{ page: 'ViewBuilder' }]);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsClicked(false);
  }, []);

  const { windowSize } = useWindowSize();

  const myRef = useRef([]);

  const { tabPanels, tabSelector, tabs } = useTabs(
    [
      {
        child: <SmartContractList itemRef={myRef.current[0]} />,
        label: `Smart Contracts`,
        value: 'Smart Contracts',
      },
      {
        child: <SpecControl />,
        label: `Your Query`,
        value: 'yourQuery',
      },
      {
        child: (
          <QueryDisplay
            style={windowSize.width > 991 ? { height: HEIGHT } : {}}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ),
        label: `Console`,
        value: 'console',
      },
    ],
    undefined,
    {
      classNames: {
        base: classes(
          'w-full test flex items-center bg-transparent overflow no-scrollbar',
          !!errorFunctionSpecs?.length && '[&>*:nth-child(2)]:text-[#F66]'
        ),
        tab: (selected) =>
          classes(
            'whitespace-pre py-[8px] md:px-[16px] px-[12px] flex-1 lg:text-[12px] text-sm',
            selected && 'bg-[#FFFFFF1A] text-white rounded-[32px] px-4'
          ),
      },
    }
  );

  myRef.current = useMemo(() => tabs.map((el, i) => myRef.current[i] ?? createRef()), []);

  const content = useMemo(() => {
    if (windowSize.width > 991) {
      return (
        <div className="flex h-full w-full min-w-full lg:flex-row flex-col gap-6">
          <Card
            style={{ height: HEIGHT }}
            className="relative flex lg:flex-row bg-[linear-gradient(180deg,#000_20.37%,#000_79.34%)] border shadow-[0px_-2px_52px_0px_rgba(200,200,200,0.06)_inset] rounded-[20px] border-solid border-[#ffffff1f] overflow-hidden"
          >
            <SmartContractList />
            <SpecControl />
          </Card>
          <QueryDisplay
            style={{ height: HEIGHT }}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      );
    }
    return (
      <div className="">
        <Tab.Group onChange={(tab) => tab === 1 && setScroll(true)}>
          <Header
            accessory={tabSelector}
            accessoryClassname=" w-full max-w-[350px] "
            className="mb-[16px] items-start justify-start block lg:overscroll-none overflow-auto no-scrollbar"
          />
          <Card
            className="bg-[#000] rounded-[25px] overflow-hidden
          border-[#1F1F1F]"
          >
            {tabPanels}
          </Card>
        </Tab.Group>
      </div>
    );
  }, [windowSize.width, tabSelector, tabPanels, functionOptions, selectedTab, setSelectedTab]);

  const scrollToError = useCallback(
    (type: 'up' | 'down') => {
      const tempIndex = errorFunctionSpecs.findIndex(
        (el) => el?.identifier === errorSpec?.identifier
      );

      const index = type === 'up' ? tempIndex - 1 : tempIndex + 1;
      if (errorFunctionSpecs[index]) {
        setErrorSpec(errorFunctionSpecs[index]);
      }
      setScroll(true);
    },
    [errorFunctionSpecs, errorSpec?.identifier]
  );

  useEffect(() => {
    if (errorFunctionSpecs) {
      const index = functionSpecs.findIndex((spec) => spec.identifier === errorSpec?.identifier);

      if (scroll) {
        const top = document.getElementById(`${index}-details`)?.offsetTop;
        document.getElementById('spec-wrapper')?.scrollTo({
          top: top && top - 14,
          behavior: 'smooth',
        });
        setScroll(false);
      }
    }
  }, [errorFunctionSpecs, scroll]);

  return (
    <div className="dark mb-6 text-white">
      <Skeleton.Provider>
        <div className="flex lg:flex-row flex-col justify-between items-start">
          <div className="flex flex-col w-full lg:w-auto">
            <Skeleton.Loader isDarkTheme className="w-[173px] h-[42px]">
              <div
                data-cy="View-builder-page-title"
                className="lg:text-[32px] leading-10 text-[24px] bg-[radial-gradient(100%_100%_at_50%_100%,_var(--tw-gradient-stops))] from-[#ffffffd9_0%] to-[#fff_100%] bg-clip-text text-transparent w-fit"
              >
                View Builder
              </div>
            </Skeleton.Loader>
            <div className="text-[#ffffffe6] lg:text-lg text-sm mt-1 mb-8 flex flex-row gap-[2px]">
              <Skeleton.Loader
                isDarkTheme
                className="h-[17px] !rounded-full"
                containerClassName="w-full max-w-[621px]"
              >
                <div className={classes('sm:w-[unset]', !isClicked && 'truncate w-[313px]')}>
                  View Builder is the most powerful and optimized environment for publishing views.
                </div>
                {!isClicked && (
                  <button
                    className="cursor-pointer sm:hidden flex whitespace-pre"
                    onClick={() => setIsClicked(true)}
                  >
                    See More
                  </button>
                )}
              </Skeleton.Loader>
            </div>
          </div>
          {!!errorFunctionSpecs?.length && (
            <div className="bg-[linear-gradient(180deg,_#000_20.37%,_#000_79.34%)] rounded-3xl items-center flex px-3 leading-[48px] mb-4 lg:mb-0  text-[#FF6666]">
              <div className="flex flex-row items-center gap-2">
                <CustomInfoIcon stroke="#FF6666" className="w-6 h-6" />
                <div className="text-xs lg:text-sm">
                  {`${
                    errorFunctionSpecs.findIndex((el) => el?.identifier === errorSpec?.identifier) +
                    1
                  }/${errorFunctionSpecs?.length}`}{' '}
                  View Error
                </div>
              </div>
              <div className="min-w-[1px] w-[1px] h-[48px] bg-[#1F1F1F] mx-3 "></div>
              <div className="text-xs lg:flex  flex-row gap-1 items-center">
                <button onClick={() => scrollToError('up')}>
                  <ChevronUpIcon className="w-5 h-5" />
                </button>
                <button onClick={() => scrollToError('down')}>
                  <ChevronDownIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          )}
        </div>
        {content}
      </Skeleton.Provider>
    </div>
  );
};
