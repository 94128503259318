import { useEffect, useMemo, useState } from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import BigNumber from 'bignumber.js';
import { ToolTip } from 'components';
import { useApi } from 'contexts';
import { ResourceView, View, useSponsorViewMutation, useUserBalanceQuery } from 'gql';
import { formatBalance } from 'utils';

interface useFundViewProps {
  view: View | ResourceView;
  minCost: string | undefined;
  minCycle?: number;
}

const useFundView = ({ view, minCost, minCycle }: useFundViewProps) => {
  // State variables
  const [amount, setAmount] = useState<number | string>('');
  const [fundRefAddress, setFundRefAddress] = useState<string | null | undefined>(null);
  const [error, setError] = useState<string | undefined>('');
  const [amountError, setAmountError] = useState('');

  // For API and session
  const {
    account,
    sessionKey,
    chainProps: { tokenDecimals },
  } = useApi();

  // Fetch user balance
  const {
    data: accountBalanceData,
    loading: isAccountBalanceLoading,
    refetch: refetchAccountBalance,
  } = useUserBalanceQuery({
    variables: { sessionKey },
    skip: !account?.address,
  });

  // User balance calculation
  const accountBalance = useMemo(() => {
    return Number(
      formatBalance(new BigNumber(accountBalanceData?.userBalance?.balance || 0), {
        symbol: '',
        decimalPlaces: 5,
        tokenDecimals,
      })
    );
  }, [accountBalanceData?.userBalance?.balance, tokenDecimals]);

  // Minimum limit calculation
  const minimumLimit = useMemo(() => {
    return Number(
      formatBalance(new BigNumber(minCost || 0), {
        symbol: '',
        decimalPlaces: 5,
        tokenDecimals,
      })
    );
  }, [minCost, tokenDecimals]);

  // Validate the amount and reset errors
  useEffect(() => {
    if (!isAccountBalanceLoading) {
      if (Number(accountBalance) <= minimumLimit) {
        setAmountError('Insufficient balance');
      } else if (minimumLimit && amount && Number(amount) < minimumLimit) {
        setAmountError('Amount is below the limit');
      } else if (amount && accountBalance && Number(amount) > accountBalance) {
        setAmountError('Amount exceeds your account balance');
      } else {
        setAmountError('');
      }
      if (error) {
        setError('');
      }
      if (fundRefAddress) {
        setFundRefAddress(null);
      }
    }
  }, [amount, accountBalance, minCost, minimumLimit, error, fundRefAddress]);

  const [sponsorView, { loading: isSponsorViewLoading }] = useSponsorViewMutation();

  // Handle funding submission
  const handleSubmit = () => {
    sponsorView({
      variables: {
        data: {
          amount: new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toString(),
          viewName: view?.name as string,
        },
        address: account?.address,
        sessionKey,
      },
      onCompleted: (data) => {
        if (data.SponsorViewMutation.status === 'Failed' && data.SponsorViewMutation.error) {
          setError(data.SponsorViewMutation.error);
          return;
        }
        setFundRefAddress(undefined);
        refetchAccountBalance();
      },
      onError: (error) => {
        setError(error.message);
      },
      refetchQueries: ['ViewStats'],
    });
  };

  // Calculation for the number of cycles gained
  const numberOfCycle = useMemo(() => {
    if (minCycle && minCost) {
      return Math.floor(
        (Number(amount) * minCycle) /
          Number(
            formatBalance(new BigNumber(minCost || ''), {
              decimalPlaces: 4,
              symbol: '',
              tokenDecimals,
            })
          )
      );
    }
    return 0;
  }, [minCycle, minCost, amount, tokenDecimals]);

  // Memoized summary component
  const Summary = useMemo(
    () => (
      <div className="rounded-3xl flex items-center justify-between bg-[linear-gradient(271deg,_#8D74F729_3%,_#D285F729_45%,_#FFAD9729_95%)] px-5 py-3">
        <div className="flex items-center text-[#FFFFFFA3]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M9.69967 14.4466C12.5597 13.6933 14.6663 11.0933 14.6663 7.99992C14.6663 4.31992 11.7063 1.33325 7.99967 1.33325C3.55301 1.33325 1.33301 5.03992 1.33301 5.03992M1.33301 5.03992V1.99992M1.33301 5.03992H2.67301H4.29301"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.33301 8C1.33301 11.68 4.31967 14.6667 7.99967 14.6667"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="3 3"
            />
          </svg>
          <div data-cy="cycle-gained-label" className="ml-2 mr-1 md:text-sm text-xxs">
            Cycles Gained
          </div>

          <ToolTip
            message={`This amount funds ${numberOfCycle} cycles`}
            anchor="center-down"
            classNames={{ content: 'top-[35px]' }}
          >
            <QuestionMarkCircleIcon className="h-[13px] w-[13px]" />
          </ToolTip>
        </div>
        <div className="md:text-sm text-xxs">{numberOfCycle} Cycles</div>
      </div>
    ),
    [numberOfCycle]
  );

  return {
    accountBalanceData,
    handleSubmit,
    isSponsorViewLoading,
    tokenDecimals,
    setAmount,
    amount,
    Summary,
    amountError,
    fundRefAddress,
    error,
  };
};

export default useFundView;
