import { useMemo } from 'react';

import { TruncateString } from '@analog/ui/src';
import { useWindowSize } from '@analog/ui/src/utils/useWindowSize';
import { Link } from 'react-router-dom';
import { ClassNames, HTMLAttributes } from 'types';
import { classes, pathTo } from 'utils';

import { Identicon } from './Identicon';

interface Props extends HTMLAttributes<unknown> {
  withLink?: boolean;
  value?: string;
  isTruncated?: boolean;
  classNames?: ClassNames<'span'>;
}

export function Publisher({
  className: cN,
  value,
  withLink,
  isTruncated = false,
  classNames,
  ...props
}: Props) {
  const {
    windowSize: { width },
  } = useWindowSize();

  const content = useMemo(() => {
    return value ? (
      <>
        <Identicon size={20} value={value} className="relative -top-[1px] mr-1" />
        <span className={classes(classNames?.span)}>
          {isTruncated ? (
            <TruncateString text={value} textLength={width > 770 ? 10 : 5} />
          ) : (
            value
          )}
        </span>
      </>
    ) : null;
  }, [value, isTruncated, classNames?.span, width]);

  const className = classes(
    'flex items-center gap-[5px] md:text-base text-xs',
    withLink && 'hyperlink',
    cN
  );

  return withLink && value ? (
    <Link to={pathTo('Publisher', value)} className={className} {...props}>
      {content}
    </Link>
  ) : (
    <div className={className} {...props}>
      {content}
    </div>
  );
}
