import useViewSmartContractList from './useViewSmartContractList';
import { NoDataDesign, ResourceList } from 'components';

function ViewSmartContractList() {
  const { smartContractList, isLoading, controls } = useViewSmartContractList();
  return (
    <>
      <ResourceList
        value={smartContractList as []}
        isLoading={isLoading}
        isEmptyView={
          <div className="w-full py-[81px] justify-center items-center flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
            <NoDataDesign className="mb-[18px]" />
            <div className="flex gap-2 flex-col mb-4">
              <span className="">Something went wrong.</span>
              <span>Could not find Smart Contracts related to this view</span>
            </div>
          </div>
        }
      />
      {controls}
    </>
  );
}

export default ViewSmartContractList;
