import { useMemo } from 'react';

import { Button, NoAPI, Skeleton } from 'components';
import { useMyProfile } from 'contexts';
import useApiKeys from 'hooks/useApiKeys';
import { usePageFilter } from 'hooks/usePageFilter';
import _ from 'lodash';
import moment from 'moment';

import { ApiKey } from './ApiKey';
import { CreateApiKey } from './CreateApiKey';
import { GetStarted } from './GetStarted';

// const NETWORKS = [
//   {
//     label: 'Testnet',
//     value: 'testnet',
//   },
//   { label: 'Mainnet', value: 'mainnet' },
// ];

export function ApiKeys({ sortBy }: { sortBy: 'testnet' | 'mainnet' | 'all' }) {
  const { isCreateVisible, setIsCreateVisible } = useMyProfile();

  const { apiKeys, hasKeys, loading: isLoading } = useApiKeys();

  const createButton = (
    <Skeleton.Loader className="h-12 sm:w-48">
      <Button onClick={() => setIsCreateVisible(true)} variant="darkThemeFilled">
        Create New API Key
      </Button>
    </Skeleton.Loader>
  );

  const list = useMemo(() => {
    return hasKeys && sortBy === 'all' ? apiKeys : [];
  }, [apiKeys, sortBy, hasKeys]);

  const sortListed = _.cloneDeep(list);
  sortListed?.sort((a, b) => {
    const dateA = moment(a?.createdAt).toDate();
    const dateB = moment(b?.createdAt).toDate();

    if (dateB > dateA) {
      return 1;
    } else if (dateB < dateA) {
      return -1;
    }

    return 0;
  });
  const { controller, currentPage } = usePageFilter(sortListed);

  return (
    <div>
      {isLoading ? (
        <>
          {[undefined, undefined].map((empty, i) => (
            <ApiKey key={`loading-${i}`} value={empty} />
          ))}
        </>
      ) : list?.length ? (
        <div className="">
          {currentPage.map((apiKey) => (
            <ApiKey key={apiKey.key} value={apiKey} />
          ))}
          {controller}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center pb-6">
          <div className="w-full py-[81px] justify-center items-center flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
            <NoAPI />
            <h3 className="mb-3 text-center">
              <Skeleton.Loader className="w-[440px]">
                {hasKeys ? 'No matching API keys' : "You don't have any API key yet!"}
              </Skeleton.Loader>
            </h3>
            {!hasKeys && (
              <>
                <div className="text-md mb-3 text-[#B2B3B8]">
                  <Skeleton.Loader className="w-50">
                    Please create your first API key.
                  </Skeleton.Loader>
                </div>
                {createButton}
              </>
            )}
          </div>
        </div>
      )}
      <GetStarted />
      <CreateApiKey isOpen={isCreateVisible} setIsOpen={setIsCreateVisible} />
    </div>
  );
}
